
import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  HashRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import PropTypes from "prop-types";
import { LocationConfirmGuidance } from "./pages/LocationConfirmGuidance";
import Theme from "../components/theme";
import { getWebConfig, getFrontPage } from "./selectors";

/* eslint-disable */
// eslint-disable-next-line
const Home = React.lazy(() => import("./pages/Home"));
// eslint-disable-next-line
const Events = React.lazy(() => import("./pages/EventsContainer"));
// eslint-disable-next-line
const About = React.lazy(() => import("./pages/About"));
// eslint-disable-next-line
const Categories = React.lazy(() => import("./pages/CategoriesContainer"));
// eslint-disable-next-line
const Search = React.lazy(() => import("./pages/SearchContainer"));
// eslint-disable-next-line
const Paths = React.lazy(() => import("./pages/Paths/PathsContainer"));
// eslint-disable-next-line
const Location = React.lazy(() => import("./pages/LocationContainer"));
// eslint-disable-next-line
const SelectStartingPoint = React.lazy(() => import("./pages/SelectStartingPointContainer"));
// eslint-disable-next-line
const GuidanceComponent = React.lazy(() => import("./pages/GuidanceComponent"));
/* eslint-enable */

const Homes = {
  menu: "menu",
  categories: "categoriesPage",
  events: "eventsPage",
  about: "aboutPage",
  search: "searchPage",
  carSearch: "carSearchPage",
  map: "mapPage",
  paths: "pathsPage",
};

//const Lang = () => { }
const initApp = () => ({ type: "INIT_APP", appType: "web" });
export function Routes(props) {
  const { dispatch, lang, config } = props;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    dispatch(initApp());
  }, [dispatch]);
  const selectedLang = lang.selection;
  useEffect(() => {
    document.documentElement.lang = selectedLang;
  }, [selectedLang]);
  const frontPage = getFrontPage(config);
  return (
    <Router>
      <Theme />
      <React.Suspense
        fallback={
          <div className="load-wrapp">
            <div className="load-3">
              <div className="line"></div>
              <div className="line"></div>
              <div className="line"></div>
            </div>
          </div>
        }
      >
        <Switch>
          <Route
            path={["/"]}
            exact
            render={(props) => {
              switch (frontPage) {
                case Homes.menu:
                  return <Home {...props} />;
                case Homes.categories:
                  return <Redirect to="/locations/0" />;
                case Homes.search:
                  return <Redirect to="/search" />;
                case Homes.about:
                  return <Redirect to="/about" />;
                case Homes.events:
                  return <Redirect to="/events/101" />;
                default:
                  return null;
              }
            }}
          />
          <Route
            path={["/locations", "/from/:from", "home/from/:from"]}
            exact
            render={(props) => <Home {...props} />}
          />
          <Route
            path={["/search", "/from/:from/search"]}
            exact
            render={(props) => <Search {...props} />}
          />
          <Route
            path={["/about", "/from/:from/about"]}
            exact
            render={(props) => <About {...props} />}
          />
          <Route
            path={["/paths/:path", "/paths/:path/start"]}
            exact
            render={(props) => <Paths {...props} />}
          />
          <Route
            path={[
              "/to/:to",
              "/location/:to",
              "/from/:from/location/:to",
              "/from/:from/to/:to",
            ]}
            exact
            render={(props) => <Location {...props} />}
          />
          <Route
            path={["/from/:from/locations/:category", "/locations/:category"]}
            exact
            render={(props) => <Categories {...props} />}
          />
          <Route
            path={["/to/:to/from", "/to/:to/selectstart"]}
            exact
            render={(props) => <SelectStartingPoint {...props} />}
          />
          <Route
            path="/to/:to/selectstart/:from"
            exact
            component={LocationConfirmGuidance}
          />
          {/* This form of link is only used in web */}
          <Route
            path={["/to/:to/from/:from/showguidance"]}
            exact
            render={(props) => <GuidanceComponent isKiosk={false} {...props} />}
          />
          {/* This form of link is only used in kiosk */}
          <Route
            path={["/from/:from/to/:to/showguidance"]}
            exact
            render={(props) => <GuidanceComponent isKiosk={true} {...props} />}
          />
          <Route
            path={["/events/:id", "/from/:from/events/:id"]}
            exact
            component={Events}
          />
        </Switch>
      </React.Suspense>
    </Router>
  );
}

Routes.propTypes = {
  dispatch: PropTypes.func.isRequired,
  lang: PropTypes.object,
  config: PropTypes.object,
};

const mapStateToProps = (state) => {
  return { lang: state.lang, config: getWebConfig(state) };
};

export const ConnectedRoutes = connect(mapStateToProps)(Routes);
