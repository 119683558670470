import { spawn,  select, put, takeLatest, call, fork} from 'redux-saga/effects'
import { getDownloadUrl, getJson } from './Api';
import { getSubdomain, getLangSettings } from '../utils/utils';
import { getWebConfig, getLocaleEvents, getEventsPlugin, isEnabled } from "./selectors";
import { loadIndex } from './lunr';

function getLang(action) {
  if (action.type === 'LANG_INIT') return action.payload.defaultLang;
  if (action.type === 'LANG_SELECTED') return action.payload;
  return ''
}

function getLangURL(lang, eventsPlugin) {
  switch (lang) {
    case 'fi_fi': return eventsPlugin.urlFi;
    case 'ru_ru': return eventsPlugin.urlRu;
    case 'se_se': return eventsPlugin.urlSe;
    case 'en_us': return eventsPlugin.urlEn;
    default:
      return '';
  }
}

/*
function* fetchResource(resourceUrl, successAction) { 
  const initUrl = yield call(getDownloadUrl, resourceUrl);
  const json = yield call(getJson, initUrl); 
  yield put({ type: "INIT_APP_SUCCEEDED", data: json.data });
}
'*/

function* fetchData(subdomain) { 
  //const subdomain = getSubdomain(window.location.hostname);
  const resourceUrl = `/${subdomain}/withoutRoutes.json`;
  const initUrl = yield call(getDownloadUrl, resourceUrl);
  const json = yield call(getJson, initUrl);
  yield put({ type: "INIT_APP_SUCCEEDED", data: json.data });
  yield put({ type: "LANG_INIT", payload: getLangSettings(json.data) });
  return json;
}
/*
function* fetchRoutes(subdomain) { 
  //const subdomain = getSubdomain(window.location.hostname);
  const resourceUrl = `/${subdomain}/routes.json`;
  const initUrl = yield call(getDownloadUrl, resourceUrl);
  const json = yield call(getJson, initUrl);
  yield put({ type: "INIT_ROUTE_SUCCEEDED", data: json.data });
  return json;
}
*/


function* fetchDeployment() { 
  const deploymentJson = yield call(getJson, 'deployment.json');
  return yield put({type: "INIT_APP_METADATA", data: deploymentJson.data});
}

function* fetchSearchIndex(subdomain) { 
  const resourceUrl = `/${subdomain}/idx.json`;
  const initUrl = yield call(getDownloadUrl, resourceUrl);
  const json = yield call(getJson, initUrl);
  yield call(loadIndex, json.data)
  if (json.data) {
    yield put({ type: "INIT_SEARCH_SUCCEEDED", });
  } else {
    yield put({ type: "INIT_SEARCH_FAILED", });
  }
  return json;
}


export function* initSaga() {
  try {
    const subdomain = getSubdomain(window.location.hostname);
    //const resourceUrl = `/${subdomain}/withoutRoutes.json`;
    //const resourceRoutesUrl = `/${subdomain}/routes.json`;
     yield put({ type: "THEME_INIT", payload: subdomain });
    yield fork(fetchData, subdomain);
     //yield fork(fetchRoutes, subdomain)
    yield fork(fetchSearchIndex, subdomain);
    yield fork(fetchDeployment);
  } catch (e) {
     yield put({type: "INIT_APP_FAILED", message: e.message});
  }
}

export function* langSaga(action) {
  try {
    const config = yield select(getWebConfig)
    const isEventsPageEnabled = isEnabled("eventsPage", config);
    if (isEventsPageEnabled) {
      const eventsPlugin = yield select(getEventsPlugin);
      const lang = getLang(action);
      const localeEvents = yield select(getLocaleEvents(lang))
      if (!localeEvents) {
        const url = getLangURL(lang, eventsPlugin);
        const events = yield call(getJson, url);
        yield put({ type: 'EVENTS_DOWNLOAD_SUCCESS', lang, payload: events.data});
      }
    }
  } catch (e) {
    yield put({type: "LANG_SELECTION_FAILED", message: e.message});
  }

}


export function* watchInit() {
  yield takeLatest('INIT_APP', initSaga)
}
export function* watchLang() {
  yield takeLatest('LANG_SELECTED', langSaga)
  yield takeLatest('LANG_INIT', langSaga)
}

export default function* rootSaga() {
  yield spawn(watchInit)
  yield spawn(watchLang)
}

/*
function lang(state = { selection: null, items: {}}, action) {
  switch (action.type) {
    case 'LANG_SELECTED':
      return Object.assign({}, state, { selection: action.payload })
    case 'LANG_INIT':
      return Object.assign({}, state, { selection: action.payload.defaultLang, items: action.payload.items})
    default:
      return state
  }
}
*/