import * as R from "ramda";

type GetTheme = (state:any)=> string
const getData = R.prop('data')
export const getTheme: GetTheme = R.path(['theme', 'themeName']) as GetTheme

export const getTranslations: any = (state:any) => {
  return R.pick(['System', 'UI', 'customUi'])(getData(state)) as any;
}

// Web config object handlers
export const getWebConfig = R.path(['data', 'modules', 'webTouchpoint']);
export const getEventsPlugin = R.path(['data', 'modules', 'eventsFeature']);
export const getLocaleEvents = (lang:any) => R.path(['eventsPlugin', lang]);
export const isEnabled = (propName: any, obj: any) => obj && (R.propEq(propName, '1', obj) || R.propEq(propName, 1, obj))
export const getFrontPage = (config:any) => R.defaultTo("")(R.prop('frontPage', config))