import { combineReducers } from 'redux'
import { orderDataById } from '../utils/orderById';
import * as R from 'ramda';

function lang(state = { selection: null, items: {} }, action) {
  switch (action.type) {
    case 'LANG_SELECTED':
      return Object.assign({}, state, { selection: action.payload })
    case 'LANG_INIT':
      return Object.assign({}, state, { selection: action.payload.defaultLang, items: action.payload.items })
    default:
      return state
  }
}

function meta(state = { initialized: false }, action) {
  switch (action.type) {
    case 'INIT_APP_SUCCEEDED':
      return Object.assign({}, state, { data: action.data.data }, { initialized: true });
    case 'INIT_APP_METADATA':
      return Object.assign({}, state, { deployment: action.data });
    case 'INIT_APP_FAILED':
      return {
        initialized: false
      }
    default:
      return state
  }
}

function data(state = {}, action) {
  switch (action.type) {
    case 'INIT_APP_SUCCEEDED':
      return Object.assign({}, state, orderDataById(action.data));
    case 'INIT_ROUTES_SUCCEEDED':
      return Object.assign({}, state, orderDataById(action.data));
    default:
      return state
  }
}

function theme(state = { themeName: null }, action) {
  if (action.type === 'THEME_INIT') {
    return Object.assign({}, state, { themeName: action.payload })
  } else {
    return state;
  }
}

function eventsPlugin(state = {}, action) {
  if (action.type === 'EVENTS_DOWNLOAD_SUCCESS') {
    return Object.assign({}, state, R.objOf(action.lang, action.payload))
  } else {
    return state;
  }
}

function search(state = { isSearchInitialized: false }, action) {
  if (action.type === 'INIT_SEARCH_SUCCEEDED') {
    return Object.assign({}, state, { isSearchInitialized: true })
  } else {
    return state;
  }
}


const rootReducer = combineReducers({
  meta,
  theme,
  data,
  lang,
  search,
  eventsPlugin,
})

export default rootReducer