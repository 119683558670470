import axios from 'axios';
import { storage } from '../config/firebase';

export function getDownloadUrl(url) {
  return storage.ref(url)
    .getDownloadURL()
}

export function getJson(url) {
  return axios.get(url)
}

