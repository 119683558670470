import React from "react";
import { createStore, compose, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import createSagaMiddleware from "redux-saga";

import RootReducer from "./reducers";
import RootSaga from "./Sagas";
import { ConnectedRoutes } from "./Routes";
import { Analytics } from "../components/Analytics";
import "./App.css";

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const preloadedState = {};
const store = createStore(
  RootReducer,
  preloadedState,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);

sagaMiddleware.run(RootSaga);

export function App() {
  return (
    <>
      <Provider store={store}>
        <ConnectedRoutes />
      </Provider>
      <Analytics />
    </>
  );
}

export default App;
