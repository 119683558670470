
//FIREBASE
exports.firebaseConfig= {
  apiKey: "AIzaSyDSzoCBar8mA43J7lECU65T42N6Plcnu7Y",
  authDomain: "palko-dev-8b3e6.firebaseapp.com",
  databaseURL: "https://palko-dev-8b3e6.firebaseio.com",
  projectId: "palko-dev-8b3e6",
  storageBucket: "palko-dev-8b3e6.appspot.com",
  messagingSenderId: "466307919094",
  appId: "1:466307919094:web:e1bc2476cf159b43"
};


